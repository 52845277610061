<template>
  <div class="with-bg stats app-container dash-stat">
    <div class="page-title">
      <h2>{{ $t('general.welcome') }}</h2>
    </div>
    <div class="filter-container">
      <el-row>
        <el-col :span="8">
          <router-link :to="{path:'/sites/list', query:{type: 'Site'}}">
            <vs-card class="success-card">
              <template #title>
                <h3>{{ $t('general.sitescount') }}</h3>
              </template>
              <template #text>
                <h2 class="stats"><count-to :start-val="0" :end-val="stats.sitescount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link :to="{path:'/sites/list', query:{type: 'pagescount'}}">
            <vs-card class="default-card">
              <template #title>
                <h3>{{ $t('general.pagescount') }}</h3>
              </template>
              <template #text>
                <h2 class="stats"><count-to :start-val="0" :end-val="stats.pagescount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link :to="{path:'/sites/monitoring', query:{type: 'changescount'}}">
            <vs-card class="warning-card">
              <template #title>
                <h3>{{ $t('general.changescount') }}</h3>
              </template>
              <template #text>
                <h2 class="stats"><count-to :start-val="0" :end-val="stats.changescount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </router-link>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <router-link :to="{path:'/administration/users', query:{type: 'Admin'}}">
            <vs-card class="default-card">
              <template #title>
                <h3>{{ $t('general.admins') }}</h3>
              </template>
              <template #text>
                <h2 class="stats"><count-to :start-val="0" :end-val="adminsCount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link :to="{path:'/administration/users', query:{type: 'Editor'}}">
            <vs-card class="secondary-card">
              <template #title>
                <h3>{{ $t('general.editors') }}</h3>
              </template>
              <template #text>
                <h2 class="stats"><count-to :start-val="0" :end-val="editorsCount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Resource from '@/api/resource';
import RoleResource from '@/api/role';
import checkPermission from '@/utils/permission'; // Vérification Permission
import CountTo from 'vue-count-to';
import checkRole from '@/utils/role'; // Vérification Roles
const statsResource = new Resource('user-stats');
const roleResource = new RoleResource();
export default {
  name: 'DashBoard',
  components: { CountTo },
  data() {
    return {
      adminsCount: 0,
      guestCount: 0,
      editorsCount: 0,
      list: null,
      total: 0,
      loading: true,
      query: {
        page: 1,
        limit: 15,
        keyword: '',
        role: '',
      },
      roles: [],
      stats: {
        sitescount: 0,
        pagescount: 0,
        changescount: 0,
      },
    };
  },

  created() {
    this.getRoles();
    this.getStats('statistics');
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },

    async getStats() {
      this.loading = true;
      const { data } = await statsResource.list();
      this.stats = data;
      this.loading = false;
    },

    async getRoles() {
      this.loading = true;
      const { data } = await roleResource.list(this.query);
      this.roles = data;
      this.nonAdminRoles = [];
      this.roles.forEach(role => {
        if (role.name.toLowerCase() !== 'admin') {
          this.nonAdminRoles.push(role);
        }
      });
      this.loading = false;
      this.setSta();
    },

    getRoleUserCount(roleName){
      const found = this.roles.find(role => role.name === roleName);
      if (found) {
        return parseInt(found.userscount);
      } else {
        return 0;
      }
    },

    setSta(){
      this.adminsCount = this.getRoleUserCount('Admin');
      this.editorsCount = this.getRoleUserCount('Editor');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
